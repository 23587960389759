<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Pesanan</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a class="btn px-0 d-inline-block">
              <i class="fa fa-share-alt text-light"></i>
            </a>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="
              jumbotron jumbotron-fluid
              mb-0
              shadow-sm
              bg-secondary
              no-side-margin
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">Rekap Ready Stock</p>
            </div>
          </div>

          <div class="row horizontal-menu-100">
            <div class="col-12 px-0">
              <div class="list-group list-group-horizontal text-center">
                <router-link
                  to="/jual/pesanan-group"
                  class="list-group-item flex-fill"
                  >PRE ORDER</router-link
                >
                <a
                  href="javascript:void(0);"
                  class="list-group-item flex-fill on"
                  >READY STOCK</a
                >
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
              <input
                type="text"
                id="searchInput"
                class="form-control"
                v-model="state.search"
                placeholder="Cari..."
              />
              <span class="input-group-append">
                <button type="submit" class="btn btn-outline-main">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </form>

          <div class="small text-truncate">Daftar Pesanan Selesai:</div>

          <div id="resultList" class="row mt-3" v-if="state.exist">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-for="pesan in state.pesanan"
              :key="pesan.id"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="row no-gutters">
                    <div class="col-9 border-right p-2 pl-3">
                      <router-link
                        :to="'/jual/pesanan/customer/' + pesan.id"
                        class="
                          card-title
                          text-main
                          font-weight-bold
                          d-block
                          mb-1
                          mt-1
                          card-link
                          stretched-link
                          text-truncate
                        "
                        >{{ pesan.pelanggan.prefix }}
                        {{ pesan.pelanggan.name }}</router-link
                      >
                      <p class="card-text mb-0 text-truncate">
                        {{ pesan.po.name }}, {{ pesan.pelanggan.alamat }}
                        {{ pesan.pelanggan.blok_no }}
                      </p>
                      <div v-html="sumData(pesan)"></div>
                    </div>
                    <div class="col-3 p-2 pl-2">
                      <div class="ml-1">
                        <form>
                          <div class="custom-control custom-checkbox mt-1 mb-2">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :checked="pesan.siap"
                              disabled
                            />
                            <label class="custom-control-label pt-1" for="siap"
                              >Siap?</label
                            >
                          </div>
                        </form>
                        <form>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :checked="pesan.terkirim"
                              disabled
                            />
                            <label class="custom-control-label pt-1" for="kirim"
                              >Kirim?</label
                            >
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .col -->

          </div>
          <!-- .row -->
          <div id="resultList" class="row mt-3" v-else>
            <p class="pl-3">Data Kosong!</p>
          </div>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      id: "",
      pesanan: {},
      originalPesanan: {},
      finish: false,
      search: "",
      exist: false,
    });
    const back = () => {
      window.history.back();
    };
    const loadData = async () => {
      await axios
        .get("/api/seller/getNonPo/getRekap/" + state.id)
        .then((res) => {
          if (res.data.success) {
            state.pesanan = res.data.pesanan;
            state.originalPesanan = res.data.pesanan;
            state.exist = res.data.exist;
          }
          state.finish = true;
        });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const sumData = (dt) => {
      let pesanan = dt.detail.length;
      let jumlah = 0;
      let total = 0;
      dt.detail.map((detail) => {
        jumlah += Number(detail.jumlah);
        total += Number(detail.total);
      });
      let cur = Math.ceil(Number(total));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];

      return (
        '<p class="card-text mb-0 text-truncate">' +
        '<small class="text-muted">' +
        pesanan +
        " pesanan, " +
        jumlah +
        " pcs," +
        '<span class="currency"> Rp. ' +
        ret +
        "</span></small></p>"
      );
    };
    //search on object produk
    const searchData = () => {
       axios
        .get("/api/seller/getNonPo/getRekap/" + state.id + '?search='+state.search)
        .then((res) => {
          if (res.data.success) {
            state.pesanan = res.data.pesanan;
            state.originalPesanan = res.data.pesanan;
            state.exist = res.data.exist;
          }
          state.finish = true;
        });
    };
    return {
      state,
      back,
      sumData,
      searchData,
    };
  },
};
</script>